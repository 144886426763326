const endpoint = {
  common: {
    default: '/api/common',
    place: 'search-place',
    searchAutocomplete: '/api/common/search-autocomplete',
    searchGgAutocomplete: '/api/common/gg-autocomplete',
    searchdetail: '/api/common/place-detail',
    searchAddAirport: '/api/common/airport-address?v2=true',
    searchAddAirportV1: '/api/common/airport-address',
    search_place_detail: 'search-place-detail',
  },
  csrf: '/api',
  auth: {
    login: '/api/auth/login-cms',
    checkLogin: '/api/auth/check-login-cms',
    checkPhoneForgot: '/api/auth/check-phone-forgot-cms',
    enterCodeForgot: '/api/auth/verify-pin',
    forgotPassword: '/api/auth/forgot-password',
    logout: '/gateway/logout',
    signUp: '/gateway/signup',
    detail: '/api/account/find',
    update: '/api/account',
    add: '/api/account',
    refresh: '/api/auth/refresh',
    delete: '/api/account',
    status: '/api/account/change-status',
    accountDeposit: '/api/account/find',
    changePassword: '/api/account/change-password',
  },

  dashboard: {
    default: '/api/dashboard',
    find: '/find',
    active_driver: '/active-driver',
    totalActiveDriver: '/api/dashboard/total-active-driver',
    chartAccount: '/api/dashboard/chart/account',
    chartRevenue: '/api/dashboard/chart/revenue',
    chartProfit: '/api/dashboard/chart/profit',
  },
  accountRank: {
    default: '/api/account-rank',
    find: '/api/account-rank/find',
  },
  customer: {
    default: '/api/account',
    find: '/api/account/find',
    status: '/change-status',
  },
  recipientAddress: {
    default: '/api/recipient-address',
  },
  groupAdmin: {
    default: '/api/group-account',
    find: '/api/group-account/find',
    findAllMenus: '/api/admin-group/find-all-menus',
    findMenuById: '/api/admin-group/find-menus',
    findAllPermissions: '/api/permission/tree',
    findPermissionsByGroup: '/api/group-account/find-permission',
  },
  bank: {
    default: '/api/bank-account',
    find: '/find',
  },

  onboard: {
    default: '/api/onboard',
    list: '/api/onboard/find',
    detail: '/api/onboard/find',
    add: '/api/onboard/',
    update: '/api/onboard',
    delete: '/api/onboard/',
    status: '/api/onboard/change-status',
  },

  partner: {
    default: '/api/partner',
    list: '/api/partner/find',
    detail: '/api/partner/find',
    add: '/api/partner/',
    update: '/api/partner',
    delete: '/api/partner/',
    status: '/api/partner/change-status',
  },

  banner: {
    default: '/api/banner',
    list: '/api/banner/find',
    detail: '/api/banner/find',
    add: '/api/banner/',
    update: '/api/banner',
    delete: '/api/banner/',
    status: '/api/banner/change-status',
  },

  faq: {
    default: '/api/faq',
    list: '/api/faq/find',
    detail: '/api/faq/find',
    add: '/api/faq',
    update: '/api/faq',
    delete: '/api/faq/',
    status: '/api/faq/change-status',
  },
  instruction: {
    default: '/api/instruction',
    list: '/api/instruction/find',
    detail: '/api/instruction/find',
    add: '/api/instruction',
    update: '/api/instruction',
    delete: '/api/instruction/',
    status: '/api/instruction/change-status',
  },

  terms: {
    default: '/api/terms-policy',
    list: '/api/terms-policy',
    detail: '/api/terms-policy',
    add: '/api/terms-policy',
    update: '/api/terms-policy',
    delete: '/api/terms-policy/',
    status: '/api/terms-policy',
  },

  rules_regulation: {
    default: '/api/rules-regulation',
    list: '/api/rules-regulation',
    detail: '/api/rules-regulation',
    add: '/api/rules-regulation',
    update: '/api/rules-regulation',
    delete: '/api/rules-regulation/',
    status: '/api/rules-regulation',
  },

  voucher: {
    default: '/api/voucher',
    list: '/api/voucher/find',
    detail: '/api/voucher/find',
    add: '/api/voucher',
    update: '/api/voucher',
    delete: 'api/voucher',
    status: '/api/voucher/change-status',
  },
  buy_more: {
    default: '/api/buy-a-lot',
    list: '/api/voucher/find',
    detail: '/api/buy-a-lot/find',
    add: '/api/buy-a-lot',
    update: '/api/buy-a-lot',
    delete: 'api/buy-a-lot',
    status: '/api/buy-a-lot/change-status',
  },

  flashSale: {
    default: '/api/flash-sale',
    list: '/api/flash-sale/find',
    detail: '/api/flash-sale/find',
    add: '/api/flash-sale',
    update: '/api/flash-sale',
    delete: 'api/flash-sale',
    status: '/api/terms-policy',
  },

  sale: {
    default: '/api/sale',
    list: '/api/sale/find',
    detail: '/api/sale/find',
    add: '/api/sale',
    update: '/api/sale',
    delete: 'api/sale',
    status: '/api/sale/change-status',
    promoHistory: '/api/sale/promo-history',
  },
  gift: {
    default: '/api/gift',
    add: '/api/gift',
    status: '/api/gift/change-status',
    detail: '/api/gift/find',
    update: '/api/gift',
  },
  getCode: {
    default: '/api/get-code',
    reportDelivery: '/api/get-code/report-delivery',
    returnProduct: '/api/get-code/return-product',
    returnSupplier: '/api/get-code/return-supplier',
    transfer: '/api/get-code/transfer',
    sales: '/api/get-code/report-sales',
    reportImport: '/api/get-code/report-import',
    reportImportPayment: '/api/get-code/report-import-payment',
    cashBook: '/api/get-code/cash-book',
    customerList: '/api/get-code/customer-list',
    reportSale: '/api/get-code/report-sales',
    warehouseBook: '/api/get-code/report-warehouse-book',
    reportCustomer: '/api/get-code/report-customer',
    reportOrderStatistic: '/api/get-code/report-order-statistic',
  },
  receipt: {
    default: '/api/receipt',
  },
  payslip: {
    default: '/api/pay-slip',
  },
  cashbook: {
    default: '/api/cash-book',
  },
  product: {
    default: '/api/product',
    find: '/api/product-category/find',
    inventory: '/api/product/inventory',
  },
  product_category: {
    default: '/api/product-category',
    find: '/api/product-category/find',
  },
  product_group: {
    default: '/api/product-group',
    find: '/api/product-group/find',
  },
  product_barcode: {
    default: '/api/product-barcode',
    find: '/api/product-barcode/find',
  },
  coupon_category: {
    default: '/api/coupon-category',
    find: '/api/coupon-category/find',
  },
  /*service manager*/
  service: {
    info: {
      default: '/api/service-info',
      update: '/api/service-info',
    },
    package: {
      default: '/api/service',
      list: '/api/service/find',
      detail: '/api/service/find',
      add: '/api/service/',
      update: '/api/service',
      delete: '/api/service',
    },
    address: {
      default: '/api/service-address',
      list: '/api/service-address/find',
      detail: '/api/service-address/find',
      add: '/api/service-address/',
      update: '/api/service-address',
      delete: '/api/service-address',
    },
  },

  /*Trip manager*/
  trip_mamager: {
    // default: '/api/booking',
    // list: '/api/booking/find',
    // add: '/api/booking/',
    // addship: '/api/booking/ship',
    // find: 'find',
    // update: '/api/booking',
    // updateSell: '/api/sell',
    // delete: '/api/booking',
    // scan: '//api/booking/confirm',
    // confirm: '//api/booking/confirm',
    // finish: '/api/booking/finish',
    // driverInfo: '/api/booking/driver-info',
    // customerInfo: '/api/booking/customer-info',
    // cancel: '/cancel',
    // history: 'find/history',
    // driversActive: '/find/drivers-active',
    // changeDriver: '/change-driver',
    findPrice: '/find-price',

    // listReferralForUser: '/api/account',

    // bookingShip: '/api/booking/find',
    // areaForBooking: '/api/booking/area-for-booking',
    // bookingSearch: '/api/booking/booking-search',
    //ship
    // ship: '/api/booking/find',
    // shipAdd: '/api/ship/create',
    // shipUpdate: '/api/ship/update',
    //OtherBooking
    // otherBooking: '/api/other-booking/find',
    // status: '/api/other-booking/change-status/',

    //thống kê tổng số chuyến
    summary: 'api/account/',
  },
  popularRoute: {
    default: '/api/popular-route',
  },

  /*Notification*/
  notification: {
    default: '/api/system-notification',
    add: '/api/system-notification',
    list: '/api/system-notification/find',
    detail: '/api/system-notification/find',
    update: '/api/system-notification',
    delete: '/api/system-notification',
  },
  categories: 'v',
  account: {
    default: '/api/account',
    find: '/api/account/find',
    // summaryDriver: '/api/account/wallet-summary',
    accountExport: '/api/account/export',
    // historyEditDriver: '/api/account/driver-change-history',
    // historyEditDriverDetail: '/api/account/driver-change-detail',
    trackingStatusAccount: '/api/account-status-tracking',
    createAdmin: '/api/account/create-admin',
    updateAdmin: '/api/account/update-admin',
    deleteAdmin: '/api/account/admin',
    resetAdmin: '/api/account/reset-pass-admin',
    // activityAreaForDriver: '/api/account/activity-area-for-driver',
    // flightSalesHistory: '/api/booking/history',
    // listAccountRegister: '/api/account/list-account-register',
    findAdminDetail: '/api/account/find-admin',
    detailBill: '/api/account/debt-detail',
  },

  importExcel: {
    pipeline: '/api/import-excel/pipeline',
    openValve: '/api/import-excel/open-valve',
  },

  wallet: {
    default: '/api/wallet-history',
    list: '/find',
    add: '/api/wallet-history',
    status: '/api/wallet-history',
    export_wallet: '/export-summary',
    export: '/export',
    refund: '/api/wallet-history/refund',
    statusDeduct: '/api/wallet-history/deduct',
  },

  report: {
    default: '/api/report',
    storeRevenue: '/api/report/chart/store-revenue',
    inventory: '/api/report/inventory',
    inventoryByDate: '/api/report/inventory-by-date',
    shipmenDetail: '/api/report/chart/shipmen-detail',
    storeRevenueDetail: '/api/report/chart/store-revenue-detail',
    listStoreRevenue: '/api/report/store-revenue',

    //Báo cáo giao hàng
    chartReportDelivery: '/api/report/chart/report-delivery',
    listReportDelivery: '/api/report/report-delivery',
    //
    chartReportImport: '/api/report/chart/import',
    chartReportPaymentImport: '/api/report/chart/import-payment',
    listReportPaymentImport: '/api/report/import-payment',
    listReportImport: '/api/report/import',
    chartReportOrderPayment: '/api/report/chart/order-payment',
    listReportOrderPayment: '/api/report/order-payment',
    listOderStatistic: '/api/report/order-statistic',

    listWarehouseBook: '/api/report/warehouse-book',
    listInventoryBelowTheNorm: '/api/report/inventory-below-the-norm',
    listInventoryAboveTheNorm: '/api/report/inventory-exceed-the-norm',
    listProductIncoming: '/api/transaction-list/inventory-exceed-the-norm',
    listInventoryExportImport: '/api/report/inventory-import-and-export',

    listProductStatistic: '/api/report/product-statistic',
    //Báo cáo tài chính
    reportProfitLoss: '/api/report/profit-and-loss',
    reportDebtCustomer: '/api/report/customer-debt',
    reportDebtSupplier: '/api/report/supplier-debt',

    //Báo cáo khách hàng
    reportCustomer: '/api/report/chart/report-customer',

    // driver: {
    //   list: '/driver/list',
    //   chart: '/driver/chart',
    //   export: '/driver/export',
    // // },
    // customer: {
    //   list: '/customer/list',
    //   chart: '/customer/chart',
    //   export: '/customer/export',
    // },
    // revenue: {
    //   list: '/revenue/list',
    //   chart: '/revenue/chart',
    //   export: '/revenue/export',
    // },
    // trip: {
    //   list: '/booking/list',
    //   chart: '/booking/chart',
    //   export: '/booking/export',
    // },
    // profit: {
    //   list: '/profit/list',
    //   chart: '/profit/chart',
    //   export: '/profit/export',
    // },
  },
  warehouse: {
    default: '/api/warehouse',
  },
  supplier: {
    default: '/api/supplier',
  },
  return_supplier: {
    default: '/api/return-supplier',
  },
  post: {
    default: '/api/post',
    postManager: {
      list: '/find',
    },
  },
  orderManager: {
    default: '/api/order',
  },
  reportPost: {
    default: '/api/post-report',
    reportManager: {
      list: '/find',
    },
  },

  school: '/school',
  grade: '/grade',
  class: '/class',
  update_class: '/class_student/update_class',
  semester: '/semester',
  shift: '/shift',
  equipment: '/equipment',
  equipment_type: '/equipment_type',
  score: '/score',
  score_type: '/score_type',
  group: '/group',
  classroom: '/classroom',
  student: '/student',
  class_student: '/class_student',

  accountTeacher: '/gateway/teacher',
  accountParent: '/gateway/parent',
  calendarSchool: '/timetable',
  document: '/document',
  subject: '/subject',

  notification_type: '/notification_type',

  lab_type: '/lab_type',
  lab_details: '/lab',

  // Quản lý địa bàn
  province: '/api/province',
  district: '/api/district',
  commune: '/api/commune',
  area_to_price: '/api/setting/area',

  // Cấu hình - Giá theo địa bàn
  setting: {
    default: '/api/setting',
    add: '/api/setting/price',
    detail: '/api/setting/price/find/',
    listRoute: '/api/setting/price/find-route/',
    // config: '/api/config?admin=1',
    // list: '/api/setting/price-by-address/find',
    list: '/api/setting/price/find',
    update: '/api/setting/price-by-address',
    // delete: '/api/setting/price-by-address',
    delete: '/api/setting/price',
    //filter-address
    addFilter: '/api/setting/filter-address',
    getFilter: '/api/setting/filter-address/find',
    deleteFilter: '/api/setting/filter-address',
    updateFilter: '/api/setting/filter-address',
    config: '/api/system-config',
  },
  splash: {
    default: '/api/splash-screen',
    find: '/api/splash-screen/find',
  },

  //đơn vị vận chuyển
  shipperConnectPartners: {
    defaultViettelPost: '/api/viettel-post',
    defaultGhtk: '/api/ghtk',
    listShipperConnectPartners: '/api/order/shipping-unit',
    disconnect: '/api/order/logout',
  },
  //khiếu nại
  complain: '/api/complain',

  news: {
    default: '/api/news',
    list: '/api/news/find',
    detail: '/api/news/find',
    add: '/api/news',
    update: '/api/news',
    delete: '/api/news',
    status: '/api/news',
  },

  //quản lý nhóm tài khoản
  adminGroup: '/api/admin-group',

  //Tài khoản Admin
  adminGroupMember: '/api/admin-group-member',

  log: '/api/log',
  systemBank: '/api/system-bank',
  chatWithAdmin: {
    thread: '/api/chat/to-admin/thread',
    messagesByThread: '/api/chat/to-admin/',
  },

  import_products: {
    default: '/api/import',
    list: '/api/import/find',
    detail: '/api/import/find',
    add: '/api/import',
    update: '/api/import',
    delete: '/api/import/',
    status: '/api/import/change-status',
    confirmImport: 'api/import/confirm-import',
    confirmCancel: 'api/import/cancel',
    payModal: 'api/import/confirm-pay',
    refundPayModal: 'api/return-supplier/refund-confirm',
    addSerial: 'api/serial/import',
    payHistory: 'api/import/pay-history',
    returnSupplier: '/api/return-supplier',
    refundAmount: '/api/import/find-price',
    printBarcode: '/api/import/products',
    excel: {
      productTemplate: '/api/excel/export-sample-import',
      importProduct: '/api/excel/import-stock',
    }
  },
  serial: {
    default: '/api/serial',
    checkSerial: '/api/serial/check-serial',
  },
  transport_products: {
    default: '/api/transfer',
    barcode: '/api/transfer/products',
  },

  purchase_order: {
    default: '/api/purchase-order',
  },
  return_product: {
    default: '/api/return-product',
  },
  sampleExcel: '/api/excel/export',
  excel: {
    history: '/api/excel',
    transfer: '/api/excel/export-transfer',
    debtHistory: '/api/excel/export-debt-history',
    importPromo: '/api/excel/import',
    importProduct: '/api/excel/export-import-product',
    debtCustomer: '/api/excel/export-debt-customer',
    reportDelivery: '/api/excel/report-delivery',
    exportOrder: '/api/excel/export-order',
    customerReturn: '/api/excel/export-return-product',
    account: '/api/excel/export-customer',
    accountTemplate: '/api/excel/export-sample',
    importAccount: '/api/excel/import-customer',
    product: '/api/excel/export-product',
    productTemplate: '/api/excel/export-sample-product',
    purchaseOrder: '/api/excel/export-purchase-order',
    reportImport: '/api/excel/export-report-import',
    reportImportPayment: '/api/excel/export-report-import-payment',
    reportExportStoreRevenue: '/api/excel/export-store-revenue',

    //Báo cáo tồn kho
    inventoryWarehouse: '/api/excel/export-inventory',
    //Báo cáo tồn kho theo date
    inventoryWarehouseByDate: '/api/excel/export-inventory-by-date',
    //Báo cáo xuất nhập tồn
    exportImportInventory: '/api/excel/inventory-import-and-export',
    //Báo cáo sổ kho
    warehouseBook: '/api/excel/export-report-warehouse-book',

    reportOrder: '/api/excel/export-report-order',
    reportProduct: '/api/excel/export-report-product',
    exportOrderPayment: '/api/excel/export-order-payment',

    reportAboveTheNorm: '/api/excel/inventory-exceed-the-norm',
    reportBelowTheNorm: '/api/excel/inventory-below-the-norm',
    customerDebt: '/api/excel/customer-debt',
    customerDetailDept: '/api/excel/customer-debt-detail',
    supplierDept: '/api/excel/supplier-debt',
    supplierDeptDetail: '/api/excel/supplier-debt-detail',
    orderList: '/api/excel/order-list',

    exportWarehouseInventory: '/api/excel/export-inventory',
    exportWarehouseInventoryByDate: '/api/excel/export-inventory-by-date',

    //Báo cáo tài chính
    reportProfitLoss: '/api/excel/profit-and-loss',
    listInProfitLoss: '/api/excel/list-in-profit-and-loss',

    cashBook: '/api/excel/export-cash-book',
  },
  //Danh sách giao dịch
  transactionList: {
    default: '/api/transaction-list',
    profitLoss: '/api/transaction-list/profit-and-loss',
    customer: '/api/transaction-list/customer-debt',
    supplier: '/api/transaction-list/supplier-debt',
  },
};
export default endpoint;
