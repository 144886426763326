import {
  BarChartOutlined,
  CalculatorOutlined,
  CloudDownloadOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
  FileWordOutlined,
  GiftOutlined,
  HomeOutlined,
  NotificationOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  SkinOutlined,
  TruckOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { EPermissionScreen, EPermissionType } from 'src/utils/permissions';
import { ROUTE_CONFIG } from './helpers';

export interface IMenuProps {
  key: string;
  label: any;
  icon?: React.ReactNode;
  permission?: string[];
  children?: IMenuProps[];
}

export const homeRouter = {
  key: ROUTE_CONFIG.HOME,
  label: <Link to={ROUTE_CONFIG.HOME}>Trang chủ</Link>,
  icon: <HomeOutlined />,
};

export const routes: IMenuProps[] = [
  homeRouter,
  {
    key: ROUTE_CONFIG.ORDER_MANAGER,
    label: 'Quản lý đơn hàng',
    icon: <DollarOutlined />,
    children: [
      {
        key: ROUTE_CONFIG.ORDER_MANAGER_ADD,
        label: <Link to={ROUTE_CONFIG.ORDER_MANAGER_ADD}>Tạo đơn và giao hàng</Link>,
        permission: [EPermissionScreen.DON_HANG + '_' + EPermissionType.ADD],
      },
      {
        key: ROUTE_CONFIG.ORDER_MANAGER_LIST,
        label: <Link to={ROUTE_CONFIG.ORDER_MANAGER_LIST}>Danh sách đơn hàng</Link>,
        permission: [EPermissionScreen.DON_HANG + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.ORDER_MANAGER_CUSTOMER_RETURN_PRODUCT,
        label: <Link to={ROUTE_CONFIG.ORDER_MANAGER_CUSTOMER_RETURN_PRODUCT}>Khách trả hàng</Link>,
        permission: [
          EPermissionScreen.KHACH_TRA_HANG + '_' + EPermissionType.VIEW,
          EPermissionScreen.KHACH_TRA_HANG + '_' + EPermissionType.ADD,
        ],
      },
    ],
  },
  {
    key: ROUTE_CONFIG.ACCOUNT,
    label: 'Quản lý tài khoản',
    icon: <UserOutlined />,
    children: [
      {
        key: ROUTE_CONFIG.ACCOUNT_CUSTOMER,
        label: <Link to={ROUTE_CONFIG.ACCOUNT_CUSTOMER}>Tài khoản khách hàng</Link>,
        permission: [EPermissionScreen.KHACH_HANG + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.ACCOUNT_RANK,
        label: <Link to={ROUTE_CONFIG.ACCOUNT_RANK}>Quản lý hạng tài khoản</Link>,
        permission: [EPermissionScreen.HANG_TAI_KHOAN + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.ADMIN_MANAGER,
        label: <Link to={ROUTE_CONFIG.ADMIN_MANAGER}>Tài khoản Admin</Link>,
        permission: ['ADMIN_VIEW'],
      },
      {
        key: ROUTE_CONFIG.GROUP_ADMIN,
        label: <Link to={ROUTE_CONFIG.GROUP_ADMIN}>Quản lý Nhóm Admin</Link>,
        permission: ['GROUP_ADMIN_VIEW'],
      },
    ],
  },
  {
    key: ROUTE_CONFIG.PRODUCT_MANAGER,
    label: 'Quản lý sản phẩm',
    icon: <SkinOutlined />,
    children: [
      {
        key: ROUTE_CONFIG.PRODUCT,
        label: <Link to={ROUTE_CONFIG.PRODUCT}>Sản phẩm</Link>,
        permission: [EPermissionScreen.SAN_PHAM + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.PRODUCT_CATEGORY,
        label: <Link to={ROUTE_CONFIG.PRODUCT_CATEGORY}>Danh mục sản phẩm</Link>,
        permission: [EPermissionScreen.DANH_MUC_SAN_PHAM + '_' + EPermissionType.VIEW],
      },
      // {
      //   key: ROUTE_CONFIG.PRODUCT_BARCODE,
      //   label: 'Barcode của sản phẩm',
      // },
      {
        key: ROUTE_CONFIG.PRODUCT_GROUP,
        label: <Link to={ROUTE_CONFIG.PRODUCT_GROUP}>SP hiển thị trang chủ</Link>,
        permission: [EPermissionScreen.NHOM_SAN_PHAM + '_' + EPermissionType.VIEW],
      },
    ],
  },
  {
    key: ROUTE_CONFIG.WARE_HOUSE_MANAGER,
    label: 'Quản lý kho',
    icon: <ShoppingCartOutlined />,
    children: [
      {
        key: ROUTE_CONFIG.WARE_HOUSE,
        label: <Link to={ROUTE_CONFIG.WARE_HOUSE}>Kho hàng</Link>,
        permission: [EPermissionScreen.KHO_HANG + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.SUPPLIER,
        label: <Link to={ROUTE_CONFIG.SUPPLIER}>Nhà cung cấp</Link>,
        permission: [EPermissionScreen.NHA_CUNG_CAP + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.ORDER_FORM,
        label: <Link to={ROUTE_CONFIG.ORDER_FORM}>Đơn đặt hàng</Link>,
        permission: [EPermissionScreen.DON_DAT_HANG + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.IMPORT_PRODUCTS,
        label: <Link to={ROUTE_CONFIG.IMPORT_PRODUCTS}>Nhập hàng</Link>,
        permission: [EPermissionScreen.NHAP_HANG + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.PAYMENT_FORM,
        label: <Link to={ROUTE_CONFIG.PAYMENT_FORM}>Đơn trả NCC</Link>,
        permission: [EPermissionScreen.DON_TRA_NCC],
      },
      {
        key: ROUTE_CONFIG.TRANSPORT_PRODUCTS,
        label: <Link to={ROUTE_CONFIG.TRANSPORT_PRODUCTS}>Điều chuyển</Link>,
        permission: [EPermissionScreen.DIEU_CHUYEN + '_' + EPermissionType.VIEW],
      },
    ],
  },
  {
    key: ROUTE_CONFIG.INCENTIVE_MANAGER,
    label: 'Quản lý Ưu Đãi',
    icon: <GiftOutlined />,
    children: [
      {
        key: ROUTE_CONFIG.SALE,
        label: <Link to={ROUTE_CONFIG.SALE}>Sale</Link>,
        permission: [EPermissionScreen.SALE + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.FLASH_SALE,
        label: <Link to={ROUTE_CONFIG.FLASH_SALE}>Flash sale</Link>,
        permission: [EPermissionScreen.FLASHSALE + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.GIVE_A_GIFT,
        label: <Link to={ROUTE_CONFIG.GIVE_A_GIFT}>Tặng quà</Link>,
        permission: [EPermissionScreen.QUA_TANG + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.BUY_MORE_DISCOUNT_MORE,
        label: <Link to={ROUTE_CONFIG.BUY_MORE_DISCOUNT_MORE}>Mua nhiều giảm nhiều</Link>,
        permission: [EPermissionScreen.MUA_NHIEU + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.VOUCHER_INCENTIVE,
        label: <Link to={ROUTE_CONFIG.VOUCHER_INCENTIVE}>Voucher</Link>,
        permission: [EPermissionScreen.VOUCHER + '_' + EPermissionType.VIEW],
      },
    ],
  },
  {
    key: ROUTE_CONFIG.BILL,
    label: 'Loại phiếu',
    icon: <CalculatorOutlined />,
    permission: [EPermissionScreen.MENU_LOAI_PHIEU],

    children: [
      // {
      //   key: ROUTE_CONFIG.BILL_RECEIPT,
      //   label: <Link to={ROUTE_CONFIG.BILL_RECEIPT}>Phiếu thu</Link>,
      //   permission: [EPermissionScreen.PHIEU_THU + '_' + EPermissionType.VIEW],
      // },
      // {
      //   key: ROUTE_CONFIG.BILL_PAYSLIP,
      //   label: <Link to={ROUTE_CONFIG.BILL_PAYSLIP}>Phiếu chi</Link>,
      //   permission: [EPermissionScreen.PHIEU_CHI + '_' + EPermissionType.VIEW],
      // },
      {
        key: ROUTE_CONFIG.BILL_RECEIPT,
        label: <Link to={ROUTE_CONFIG.BILL_RECEIPT}>Phiếu thu</Link>,
        permission: [
          EPermissionScreen.PHIEU_THU + '_' + EPermissionType.VIEW,
          EPermissionScreen.LOAI_PHIEU_THU + '_' + EPermissionType.VIEW,
        ],
      },
      {
        key: ROUTE_CONFIG.BILL_PAYSLIP,
        label: <Link to={ROUTE_CONFIG.BILL_PAYSLIP}>Phiếu chi</Link>,
        permission: [EPermissionScreen.PHIEU_CHI + '_' + EPermissionType.VIEW, EPermissionScreen.LOAI_PHIEU_CHI],
      },
      {
        key: ROUTE_CONFIG.CASH_BOOK,
        label: <Link to={ROUTE_CONFIG.CASH_BOOK}>Sổ quỹ</Link>,
        permission: [EPermissionScreen.BAO_CAO_SO_QUY + '_' + EPermissionType.VIEW],
      },
    ],
  },
  {
    key: ROUTE_CONFIG.NOTIFICATION,
    label: <Link to={ROUTE_CONFIG.NOTIFICATION}>Quản lý thông báo</Link>,
    icon: <NotificationOutlined />,
    permission: [EPermissionScreen.MENU_THONG_BAO + '_' + EPermissionType.VIEW],
  },
  {
    key: ROUTE_CONFIG.CONTENT_MANAGER,
    label: 'Quản lý nội dung',
    icon: <FileWordOutlined />,
    children: [
      {
        key: ROUTE_CONFIG.BANNER_MANAGER,
        label: <Link to={ROUTE_CONFIG.BANNER_MANAGER}>Quản lý banner</Link>,
        permission: [EPermissionScreen.BANNER + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.ONBOARD_MANAGER,
        label: <Link to={ROUTE_CONFIG.ONBOARD_MANAGER}>Quản lý Onboard</Link>,
        permission: [EPermissionScreen.ONBOARD + '_' + EPermissionType.VIEW],
      },
      {
        key: ROUTE_CONFIG.FAQ,
        label: <Link to={ROUTE_CONFIG.FAQ}>Quản lý FAQ</Link>,
        permission: [EPermissionScreen.FAQ + '_' + EPermissionType.VIEW],
      },
      // {
      //   key: ROUTE_CONFIG.TERMS_OF_USE,
      //   label: 'Điều khoản - chính sách',
      // },
      // {
      //   key: ROUTE_CONFIG.SPLASH,
      //   label: 'Quản lý màn splash',
      // },
      // {
      //   key: ROUTE_CONFIG.NEWS,
      //   label: 'Quản lý tin Tức',
      // },
    ],
  },
  // {
  //   key: ROUTE_CONFIG.AREA,
  //   label: 'Quản lý địa bàn',
  //   icon: <AimOutlined />,
  //   children: [
  //     {
  //       key: ROUTE_CONFIG.PROVINCE,
  //       label: 'Tỉnh thành',
  //     },
  //     {
  //       key: ROUTE_CONFIG.DISTRICT,
  //       label: 'Quận huyện',
  //     },
  //     {
  //       key: ROUTE_CONFIG.COMMUNE,
  //       label: 'Phường xã',
  //     },
  //   ],
  // },

  {
    key: ROUTE_CONFIG.CONFIG,
    label: 'Cấu hình',
    icon: <SettingOutlined />,
    children: [
      {
        key: ROUTE_CONFIG.CONFIG_SYSTEM,
        permission: [EPermissionScreen.MENU_CAU_HINH + '_' + EPermissionType.VIEW],
        label: <Link to={ROUTE_CONFIG.CONFIG_SYSTEM}>Cấu hình</Link>,
      },
      {
        key: ROUTE_CONFIG.TERMS_POLICY,
        label: <Link to={ROUTE_CONFIG.TERMS_POLICY}>Điều khoản và chính sách</Link>,
        permission: [EPermissionScreen.DIEU_KHOAN_VA_CHINH_SACH + '_' + EPermissionType.ADD],
      },
    ],
  },
  {
    key: ROUTE_CONFIG.INSTRUCTION,
    label: <Link to={ROUTE_CONFIG.INSTRUCTION}>Hướng dẫn sử dụng</Link>,
    icon: <ExclamationCircleOutlined />,
    permission: [EPermissionScreen.HDSD + '_' + EPermissionType.VIEW],
  },
  {
    key: ROUTE_CONFIG.REPORT_MANAGER,
    label: 'Báo cáo',
    icon: <BarChartOutlined />,
    children: [
      {
        key: ROUTE_CONFIG.REPORT_MANAGER_SALES,
        label: <Link to={ROUTE_CONFIG.REPORT_MANAGER_SALES}>Báo cáo bán hàng</Link>,
        permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
      },
      {
        key: ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT,
        label: <Link to={ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT}>Báo cáo nhập hàng</Link>,
        permission: [EPermissionScreen.BAO_CAO_NHAP_HANG],
      },
      {
        key: ROUTE_CONFIG.REPORT_MANAGER_WAREHOUSE,
        label: <Link to={ROUTE_CONFIG.REPORT_MANAGER_WAREHOUSE}>Báo cáo kho</Link>,
        permission: [EPermissionScreen.BAO_CAO_KHO],
      },
      {
        key: ROUTE_CONFIG.REPORT_MANAGER_FINANCE,
        label: <Link to={ROUTE_CONFIG.REPORT_MANAGER_FINANCE}>Báo cáo tài chính</Link>,
        permission: [EPermissionScreen.BAO_CAO_TAI_CHINH],
      },
      {
        key: ROUTE_CONFIG.REPORT_MANAGER_CUSTOMER,
        label: <Link to={ROUTE_CONFIG.REPORT_MANAGER_CUSTOMER}>Báo cáo khách hàng</Link>,
        permission: [EPermissionScreen.BAO_CAO_KHACH_HANG],
      },
    ],
  },
  {
    key: ROUTE_CONFIG.SHIPPER,
    label: 'Vận chuyển',
    icon: <TruckOutlined />,
    permission: [EPermissionScreen.MENU_VAN_CHUYEN],
    children: [
      {
        key: ROUTE_CONFIG.SHIPPER_CONNECT_PARTNERS,
        label: <Link to={ROUTE_CONFIG.SHIPPER_CONNECT_PARTNERS}>Kết nối đối tác</Link>,
        permission: [EPermissionScreen.KET_NOI_DOI_TAC],
      },
    ],
  },
  {
    key: ROUTE_CONFIG.EXCEL_HISTORY,
    label: <Link to={ROUTE_CONFIG.EXCEL_HISTORY}>Lịch sử Excel</Link>,
    icon: <CloudDownloadOutlined />,
    permission: [EPermissionScreen.MENU_EXCEL],
  },
  // {
  //   key: ROUTE_CONFIG.COMPLAIN_MANAGER,
  //   label: 'Khiếu nại,kiến nghị',
  //   icon: <ExclamationCircleOutlined />,
  // },
  // {
  //   key: ROUTE_CONFIG.CHAT,
  //   label: 'Reikatachi Chat',
  //   icon: <MessageOutlined />,
  // },
];

export const mapsRoutePermissions = {
  [ROUTE_CONFIG.HOME]: [EPermissionScreen.MENU_TRANG_CHU],
  [ROUTE_CONFIG.ORDER_MANAGER]: [EPermissionScreen.DON_HANG, EPermissionScreen.KHACH_TRA_HANG],
  [ROUTE_CONFIG.ACCOUNT]: [
    EPermissionScreen.MENU_TAI_KHOAN,
    EPermissionScreen.KHACH_HANG,
    EPermissionScreen.HANG_TAI_KHOAN,
  ],
  [ROUTE_CONFIG.PRODUCT_MANAGER]: [
    EPermissionScreen.SAN_PHAM,
    EPermissionScreen.DANH_MUC_SAN_PHAM,
    EPermissionScreen.NHOM_SAN_PHAM,
    EPermissionScreen.NHOM_SAN_PHAM,
  ],
  [ROUTE_CONFIG.WARE_HOUSE_MANAGER]: [
    EPermissionScreen.KHO_HANG,
    EPermissionScreen.NHA_CUNG_CAP,
    EPermissionScreen.DON_DAT_HANG,
    EPermissionScreen.NHAP_HANG,
    EPermissionScreen.DON_TRA_NCC,
    EPermissionScreen.DIEU_CHUYEN,
  ],
  [ROUTE_CONFIG.INCENTIVE_MANAGER]: [
    EPermissionScreen.SALE,
    EPermissionScreen.FLASHSALE,
    EPermissionScreen.QUA_TANG,
    EPermissionScreen.MUA_NHIEU,
    EPermissionScreen.VOUCHER,
  ],
  [ROUTE_CONFIG.BILL]: [
    EPermissionScreen.PHIEU_THU,
    EPermissionScreen.PHIEU_CHI,
    EPermissionScreen.LOAI_PHIEU_THU,
    EPermissionScreen.LOAI_PHIEU_CHI,
  ],
  [ROUTE_CONFIG.SHIPPER]: [EPermissionScreen.MENU_VAN_CHUYEN],
  [ROUTE_CONFIG.NOTIFICATION]: [EPermissionScreen.MENU_THONG_BAO],
  [ROUTE_CONFIG.CONTENT_MANAGER]: [
    EPermissionScreen.MENU_NOI_DUNG,
    EPermissionScreen.ONBOARD,
    EPermissionScreen.FAQ,
    EPermissionScreen.BANNER,
  ],
  [ROUTE_CONFIG.CONFIG]: [EPermissionScreen.MENU_CAU_HINH],
  [ROUTE_CONFIG.EXCEL_HISTORY]: [EPermissionScreen.MENU_EXCEL],
  [ROUTE_CONFIG.INSTRUCTION]: [EPermissionScreen.HDSD],
  [ROUTE_CONFIG.REPORT_MANAGER]: [
    EPermissionScreen.BAO_CAO_BAN_HANG,
    EPermissionScreen.BAO_CAO_KHO,
    EPermissionScreen.BAO_CAO_NHAP_HANG,
  ],
  [ROUTE_CONFIG.CONFIG_SYSTEM]: [EPermissionScreen.MENU_CAU_HINH],
  [ROUTE_CONFIG.TERMS_POLICY]: [EPermissionScreen.DIEU_KHOAN_VA_CHINH_SACH],
};
